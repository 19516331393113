@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
html {
  scroll-behavior: smooth;
  /* overflow-y: auto;*/
  overflow-x: hidden;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  /* width: 100vh;
  height: 100vh; */
  /* font-family: "Ubuntu"; */
  font-family: "Rubik", sans-serif;
  /* overflow-y: scroll;
  padding-left: "17px !important";
  padding-right: "17px !important"; */
}

a {
  color: #296dfa;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

#html5qr-code-full-region__dashboard_section_swaplink {
  visibility: hidden;
}

#html5qr-code-full-region > div:first-child {
  display: none;
}





.fil0 {
    fill: #0099FF
}

.fil1 {
    fill: #2EACFF
}

.fil3 {
    fill: #F2F2F2
}

.fil2 {
    fill: #E5E5E5;
    fill-rule: nonzero
}
.headerCss {
  /* width: 100%; */
  background-color: var(--app-backgroundColor);
  /* overflow-x: hidden; */
  max-height: 60px;
  height: 60px;
  min-height: 60px;
  display: flex;
}
.header__box {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  background-color: var(--app-backgroundColor);
}
.header__boxCss {
  display: flex;
  align-items: center;
}
.header__label {
  font-size: 22px;
  /* font-weight: 600; */
  width: auto;
  color: var(--app-color);
}
.header__date {
  font-size: 16px;
  width: auto;
  width: 100% !important;
  color: var(--app-color);
}
.header__boxPaddingCss {
  display: flex;
  justify-content: flex-end;
}
.header__boxPaddingCss span {
  color: var(--app-color);
  cursor: pointer;
}
.header__typography {
  font-weight: 500;
  color: var(--app-color);
  background-color: var(--app-backgroundColor);
}
.header--user {
  /* margin-right: 80px; */
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: end;
}

.header__user--menu {
  margin-top: 35px;
  position: absolute;
  color: "#fff";
}
.header--user span {
  margin-left: 5px;
  color: #fff;
}

/* css for tripple toggle button */
.tripple-toggle-container input[type="radio"] {
  display: none;
}

.tripple-toggle-container label {
  color: white;
  z-index: 10;
  cursor: pointer;
}

.tripple-toggle-container h4 {
  margin: 0;
  font-weight: 500;
}

.tripple-toggle-container .black-font {
  color: black !important;
}

.tripple-toggle-container {
  display: inline-block;
  vertical-align: middle;
  width: 280px;
  height: 50px;
  border-radius: 100px;
  background-color: #36589f;
  position: absolute;
  /* top: 20%; */
  right: 1%;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.5);
}

.tripple-toggle-container .switch {
  height: 44px;
  width: 80px;
  background-color: white;
  border-radius: 100px;
  position: absolute;
  left: 3px;
  top: 3px;
  transition: ease-in;
}

/*---------------- LEFT ------------------- */
.tripple-toggle-container .switch.left-to-center {
  animation-name: leftToCenter;
  animation-duration: 0.5s;
}

.tripple-toggle-container .switch.left-to-right {
  animation-name: leftToRight;
  animation-duration: 0.5s;
}

.tripple-toggle-container .switch.left-position {
  left: 3px;
}

.tripple-toggle-container .left-label {
  position: absolute;
  /* font-weight: 600; */
}

.tripple-toggle-container #left + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

.tripple-toggle-container #left:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

@keyframes leftToCenter {
  from {
    left: 3px;
  }
  to {
    left: 85px;
  }
}

@keyframes leftToRight {
  from {
    left: 3px;
  }
  to {
    left: 167px;
  }
}

/* -------------------- center ------------------ */
.tripple-toggle-container .switch.center-to-left {
  animation-name: centerToLeft;
  animation-duration: 0.5s;
}

.tripple-toggle-container .switch.center-to-right {
  animation-name: centerToRight;
  animation-duration: 0.5s;
}

.tripple-toggle-container .switch.center-position {
  left: 88px;
  width: 100px;
}

.tripple-toggle-container .center-label {
  position: absolute;
  left: 100px;
}

.tripple-toggle-container #center + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

.tripple-toggle-container #center:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

@keyframes centerToLeft {
  from {
    left: 85px;
  }
  to {
    left: 3px;
  }
}

@keyframes centerToRight {
  from {
    left: 85px;
  }
  to {
    right: 1px;
  }
}

/* ------------------- RIGHT ------------------- */
.tripple-toggle-container .switch.right-to-left {
  animation-name: rightToLeft;
  animation-duration: 0.5s;
}

.tripple-toggle-container .switch.right-to-center {
  animation-name: rightToCenter;
  animation-duration: 0.5s;
}

.tripple-toggle-container .switch.right-position {
  left: 197px;
}

.tripple-toggle-container .right-label {
  position: absolute;
  right: 2px;
}

.tripple-toggle-container #right + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

.tripple-toggle-container #right:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

@keyframes rightToLeft {
  from {
    left: 167px;
  }
  to {
    left: 3px;
  }
}

@keyframes rightToCenter {
  from {
    left: 167px;
  }
  to {
    right: 85px;
  }
}
@media (max-width: 400px) {
  .header__date {
    font-size: 14px;
    text-wrap: wrap;
    width: 100% !important; 
    /* word-break: break-all; */
    word-break: break-word;
    width: 94px;
  }
}
@media (max-width: 600px) {
  .header--user {
    margin-right: 0px;
    /* margin-left: 50px; */
  }
  .header__boxCss {
    width: 80px;
  }
  .header__label {
    font-size: 18px;
    width: 180px;
    /* text-align: justify; */
    word-wrap: break-word;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .header--user span {
    /* border: 1px solid red; */
    width: auto;
    text-align: right;
    word-wrap: break-word;
  }

  .tripple-toggle-container {
    width: 200px;
    height: 38px;
  }

  .tripple-toggle-container #left + label {
    height: 38px;
    width: 55px;
  }
  .tripple-toggle-container #left:checked + label {
    height: 38px;
    width: 55px;
  }
  .tripple-toggle-container #center + label {
    height: 38px;
    width: 85px;
  }
  .tripple-toggle-container #center:checked + label {
    height: 38px;
    width: 85px;
  }
  .tripple-toggle-container .center-label {
    left: 60px;
  }
  .tripple-toggle-container #right + label {
    height: 38px;
    width: 55px;
  }
  .tripple-toggle-container #right:checked + label {
    height: 38px;
    width: 55px;
  }
  .tripple-toggle-container .switch {
    height: 32px;
    width: 50px;

    left: 3px;
    /* top: 3px; */
  }
  .tripple-toggle-container .switch.center-position {
    left: 50px;
  }
  .tripple-toggle-container .switch.right-position {
    left: 147px;
  }
  @keyframes leftToCenter {
    from {
      left: 3px;
    }
    to {
      left: 50px;
    }
  }
}

/* .organizer {
  /* overflow: hidden; 
} */

.organizer__header {
  width: 100%;
  background-color: #3a6ea5;
  height: 60px;
  color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;
}
.organizer__header h2 {
  font-family: "Rubik", sans-serif;
  padding: 10px;
  margin-left: 100px;
  font-weight: 500;
  font-size: 35px;
}
.organizer__header--user {
  margin-right: 80px;
  display: flex;
  align-items: center;
}
.user__icon {
  cursor: pointer;
}
.header__user--menu {
  margin-top: 35px;
  position: absolute;
}
.logo {
  /* width: 60px; */
  height: 55px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.left__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  /* padding: 10px; */
  margin-left: 30px;
  /* border: 1px solid white; */
  /* width: 10%; */
}
.left__heading p {
  font-family: "Rubik", sans-serif;
  font-size: 30px;
  font-weight: 500;
  /* position: absolute; */
}
.eventName {
  position: absolute;
  left: 120px;
}
.profile_left_heading {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 20px;
}
.profile_left_heading p {
  font-family: "Rubik", sans-serif;
  font-size: 30px;
  font-weight: 500;
  /* position: absolute; */
  left: 50px;
}
.organizer__sidebar {
  background-color: #001f54;
  width: 80px;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  color: #fff;
  z-index: 2;
  /* -ms-overflow-style: none; 
  scrollbar-width: none; 
  overflow-y: scroll; */
}
/* .organizer__sidebar::-webkit-scrollbar {
  display: none; 
} */
.sidebar__top {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  width: 80px;
  /* height: 30px; */
  margin: auto;
  margin-top: 16px;
  transition: 0.3s;
  border-radius: 5px;
  /* cursor: pointer; */
}
/* .sidebar__top:hover {
  background-color: #3a6ea5;
  color: #00003c;
} */

hr {
  margin-top: 13px;
  border: 1px solid #3a6ea5;
}
.sidebar__menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 38px;
}
.icon {
  color: #fff;
  font-size: 240% !important;
  /* margin-bottom: 50px; */
  padding: 6px;
  cursor: pointer;
  /* background-color: #020887; */
  border-radius: 20px;
  transition: 0.8s;
}
.selectedIcon {
  background-color: #3a6ea5;
  color: #fff;
  width: 60%;
}

.menu {
  margin-bottom: 28px;
  padding: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  /* justify-content: space-between; */
  width: 60%;
  height: 38px;
  /* transition: 0.3s; */
  /* margin-left: 8px; */
}
.menu p {
  display: none;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  background-color: #3a6ea5;
  font-family: "Rubik", sans-serif;
  height: 30px;
  padding: 8px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  transition: 0.3s;
  cursor: pointer;
}

.menu:hover {
  background-color: #3a6ea5;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.menu:hover p {

  display: block;
  transition: 0.3s;
}

.organizer__mainBody {
  /* width: 90%;
  margin: auto; */
  margin-top: 60px;

  margin-left: 100px;
  margin-right: 10px;
  overflow-y: scroll;
}
.organizer__mainBody::-webkit-scrollbar {
  display: none;
}
.organizer__header--links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
}
.link {
  width: 200px;
  padding: 0px 5px;
}
.link p {
  font-size: 25px;
}
.organizer__header--links p {
  font-family: "Rubik", sans-serif;
  text-align: center;
}

.organizer__header--links p:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.p-bg {
  background-color: rgba(135, 206, 250, 0.4);
  color: #ffffff;
  border-radius: 4px;
  padding: 6px;
}
@media (max-width: 788px) {
  .organizer__sidebar {
    display: none;
  }
  .organizer__header h2 {
    font-size: 25px;
  }
  .organizer__mainBody {
    margin-left: 0px;
  }
}

@media (max-width: 400px) {
  .organizer__header h2 {
    font-size: 18px;
  }
  .organizer__header--user {
    margin-right: 5px;
  }
  .left__heading {
    margin-left: 0px;
  }
  .left__heading p {
    font-size: 16px;
  }
  .eventName {
    left: 80px;
    width: 90px;
    text-wrap: wrap;
    word-break: break-all;
  }
}

.gm-style .gm-style-iw {
  /* background-color: #252525 !important; */
  top: 0 !important;
  left: 0 !important;
  /* width: 60% !important; */
  /* min-height: 200px; */
  /* !important;
  min-height: 70px; */
  /* !important; */
  /* max-height: 200px; */
  overflow: hidden;
  padding-top: 10px;
  display: block !important;
  border: 4px solid aqua;
}
.gm-style .gm-style-iw {
  /* height: 200px;
  min-height: 200px;
  max-height: 200px; */
  /* height: 120px;
  max-height: 240px; */
  overflow-y: auto;
}
.gm-style .gm-style-iw-t::after {
  background: linear-gradient(
    45deg,
    rgb(0 255 255) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
}
/*style the paragraph tag*/
.gm-style .gm-style-iw #google-popup p {
  padding: 10px;
}

/*style the annoying little arrow at the bottom*/
.gm-style div div div div div div div div {
  /* background-color: #252525 !important; */
  margin: 0;
  padding: 0;
  top: 0;
  font-size: 16px;
}

/*style the link*/
.gm-style div div div div div div div div a {
  font-weight: bold;
}

.root {
  width: 100%;
  margin: auto;
  margin-top: 10px;
}
.toolbar {
  background-color: #ececea;
  border-radius: 5px;
}
.blockType a {
  color: #000;
  text-decoration: none;
}

body {
  --app-color: #ffffff;
  --app-backgroundColor: #3a6ea5;
  /* for full calender library */
  --fc-button-bg-color: var(--app-backgroundColor);
  --fc-button-hover-bg-color: #3788d8;
  --fc-button-border-color: var(--app-backgroundColor);
  --fc-button-hover-border-color: var(--app-backgroundColor);
}
.popoverStyle {
  z-index: 9999 !important;
}

/* --app-backgroundColor: #2c78ff; */



.rdw-colorpicker-modal {
  width: 215px !important;
  height: 128px !important;
  top: 16px !important;
  left: 5px !important;
}

