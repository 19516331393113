body {
  --app-color: #ffffff;
  --app-backgroundColor: #3a6ea5;
  /* for full calender library */
  --fc-button-bg-color: var(--app-backgroundColor);
  --fc-button-hover-bg-color: #3788d8;
  --fc-button-border-color: var(--app-backgroundColor);
  --fc-button-hover-border-color: var(--app-backgroundColor);
}
.popoverStyle {
  z-index: 9999 !important;
}

/* --app-backgroundColor: #2c78ff; */



.rdw-colorpicker-modal {
  width: 215px !important;
  height: 128px !important;
  top: 16px !important;
  left: 5px !important;
}
