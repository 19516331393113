.headerCss {
  /* width: 100%; */
  background-color: var(--app-backgroundColor);
  /* overflow-x: hidden; */
  max-height: 60px;
  height: 60px;
  min-height: 60px;
  display: flex;
}
.header__box {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  background-color: var(--app-backgroundColor);
}
.header__boxCss {
  display: flex;
  align-items: center;
}
.header__label {
  font-size: 22px;
  /* font-weight: 600; */
  width: auto;
  color: var(--app-color);
}
.header__date {
  font-size: 16px;
  width: auto;
  width: 100% !important;
  color: var(--app-color);
}
.header__boxPaddingCss {
  display: flex;
  justify-content: flex-end;
}
.header__boxPaddingCss span {
  color: var(--app-color);
  cursor: pointer;
}
.header__typography {
  font-weight: 500;
  color: var(--app-color);
  background-color: var(--app-backgroundColor);
}
.header--user {
  /* margin-right: 80px; */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
}

.header__user--menu {
  margin-top: 35px;
  position: absolute;
  color: "#fff";
}
.header--user span {
  margin-left: 5px;
  color: #fff;
}

/* css for tripple toggle button */
.tripple-toggle-container input[type="radio"] {
  display: none;
}

.tripple-toggle-container label {
  color: white;
  z-index: 10;
  cursor: pointer;
}

.tripple-toggle-container h4 {
  margin: 0;
  font-weight: 500;
}

.tripple-toggle-container .black-font {
  color: black !important;
}

.tripple-toggle-container {
  display: inline-block;
  vertical-align: middle;
  width: 280px;
  height: 50px;
  border-radius: 100px;
  background-color: #36589f;
  position: absolute;
  /* top: 20%; */
  right: 1%;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.5);
}

.tripple-toggle-container .switch {
  height: 44px;
  width: 80px;
  background-color: white;
  border-radius: 100px;
  position: absolute;
  left: 3px;
  top: 3px;
  transition: ease-in;
}

/*---------------- LEFT ------------------- */
.tripple-toggle-container .switch.left-to-center {
  animation-name: leftToCenter;
  animation-duration: 0.5s;
}

.tripple-toggle-container .switch.left-to-right {
  animation-name: leftToRight;
  animation-duration: 0.5s;
}

.tripple-toggle-container .switch.left-position {
  left: 3px;
}

.tripple-toggle-container .left-label {
  position: absolute;
  /* font-weight: 600; */
}

.tripple-toggle-container #left + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

.tripple-toggle-container #left:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

@keyframes leftToCenter {
  from {
    left: 3px;
  }
  to {
    left: 85px;
  }
}

@keyframes leftToRight {
  from {
    left: 3px;
  }
  to {
    left: 167px;
  }
}

/* -------------------- center ------------------ */
.tripple-toggle-container .switch.center-to-left {
  animation-name: centerToLeft;
  animation-duration: 0.5s;
}

.tripple-toggle-container .switch.center-to-right {
  animation-name: centerToRight;
  animation-duration: 0.5s;
}

.tripple-toggle-container .switch.center-position {
  left: 88px;
  width: 100px;
}

.tripple-toggle-container .center-label {
  position: absolute;
  left: 100px;
}

.tripple-toggle-container #center + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

.tripple-toggle-container #center:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

@keyframes centerToLeft {
  from {
    left: 85px;
  }
  to {
    left: 3px;
  }
}

@keyframes centerToRight {
  from {
    left: 85px;
  }
  to {
    right: 1px;
  }
}

/* ------------------- RIGHT ------------------- */
.tripple-toggle-container .switch.right-to-left {
  animation-name: rightToLeft;
  animation-duration: 0.5s;
}

.tripple-toggle-container .switch.right-to-center {
  animation-name: rightToCenter;
  animation-duration: 0.5s;
}

.tripple-toggle-container .switch.right-position {
  left: 197px;
}

.tripple-toggle-container .right-label {
  position: absolute;
  right: 2px;
}

.tripple-toggle-container #right + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

.tripple-toggle-container #right:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  border-radius: 100px;
}

@keyframes rightToLeft {
  from {
    left: 167px;
  }
  to {
    left: 3px;
  }
}

@keyframes rightToCenter {
  from {
    left: 167px;
  }
  to {
    right: 85px;
  }
}
@media (max-width: 400px) {
  .header__date {
    font-size: 14px;
    text-wrap: wrap;
    width: 100% !important; 
    /* word-break: break-all; */
    word-break: break-word;
    width: 94px;
  }
}
@media (max-width: 600px) {
  .header--user {
    margin-right: 0px;
    /* margin-left: 50px; */
  }
  .header__boxCss {
    width: 80px;
  }
  .header__label {
    font-size: 18px;
    width: 180px;
    /* text-align: justify; */
    word-wrap: break-word;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .header--user span {
    /* border: 1px solid red; */
    width: auto;
    text-align: right;
    word-wrap: break-word;
  }

  .tripple-toggle-container {
    width: 200px;
    height: 38px;
  }

  .tripple-toggle-container #left + label {
    height: 38px;
    width: 55px;
  }
  .tripple-toggle-container #left:checked + label {
    height: 38px;
    width: 55px;
  }
  .tripple-toggle-container #center + label {
    height: 38px;
    width: 85px;
  }
  .tripple-toggle-container #center:checked + label {
    height: 38px;
    width: 85px;
  }
  .tripple-toggle-container .center-label {
    left: 60px;
  }
  .tripple-toggle-container #right + label {
    height: 38px;
    width: 55px;
  }
  .tripple-toggle-container #right:checked + label {
    height: 38px;
    width: 55px;
  }
  .tripple-toggle-container .switch {
    height: 32px;
    width: 50px;

    left: 3px;
    /* top: 3px; */
  }
  .tripple-toggle-container .switch.center-position {
    left: 50px;
  }
  .tripple-toggle-container .switch.right-position {
    left: 147px;
  }
  @keyframes leftToCenter {
    from {
      left: 3px;
    }
    to {
      left: 50px;
    }
  }
}
