@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");
/* .organizer {
  /* overflow: hidden; 
} */

.organizer__header {
  width: 100%;
  background-color: #3a6ea5;
  height: 60px;
  color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;
}
.organizer__header h2 {
  font-family: "Rubik", sans-serif;
  padding: 10px;
  margin-left: 100px;
  font-weight: 500;
  font-size: 35px;
}
.organizer__header--user {
  margin-right: 80px;
  display: flex;
  align-items: center;
}
.user__icon {
  cursor: pointer;
}
.header__user--menu {
  margin-top: 35px;
  position: absolute;
}
.logo {
  /* width: 60px; */
  height: 55px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.left__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  /* padding: 10px; */
  margin-left: 30px;
  /* border: 1px solid white; */
  /* width: 10%; */
}
.left__heading p {
  font-family: "Rubik", sans-serif;
  font-size: 30px;
  font-weight: 500;
  /* position: absolute; */
}
.eventName {
  position: absolute;
  left: 120px;
}
.profile_left_heading {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 20px;
}
.profile_left_heading p {
  font-family: "Rubik", sans-serif;
  font-size: 30px;
  font-weight: 500;
  /* position: absolute; */
  left: 50px;
}
.organizer__sidebar {
  background-color: #001f54;
  width: 80px;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  color: #fff;
  z-index: 2;
  /* -ms-overflow-style: none; 
  scrollbar-width: none; 
  overflow-y: scroll; */
}
/* .organizer__sidebar::-webkit-scrollbar {
  display: none; 
} */
.sidebar__top {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  width: 80px;
  /* height: 30px; */
  margin: auto;
  margin-top: 16px;
  transition: 0.3s;
  border-radius: 5px;
  /* cursor: pointer; */
}
/* .sidebar__top:hover {
  background-color: #3a6ea5;
  color: #00003c;
} */

hr {
  margin-top: 13px;
  border: 1px solid #3a6ea5;
}
.sidebar__menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 38px;
}
.icon {
  color: #fff;
  font-size: 240% !important;
  /* margin-bottom: 50px; */
  padding: 6px;
  cursor: pointer;
  /* background-color: #020887; */
  border-radius: 20px;
  transition: 0.8s;
}
.selectedIcon {
  background-color: #3a6ea5;
  color: #fff;
  width: 60%;
}

.menu {
  margin-bottom: 28px;
  padding: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  /* justify-content: space-between; */
  width: 60%;
  height: 38px;
  /* transition: 0.3s; */
  /* margin-left: 8px; */
}
.menu p {
  display: none;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  background-color: #3a6ea5;
  font-family: "Rubik", sans-serif;
  height: 30px;
  padding: 8px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  transition: 0.3s;
  cursor: pointer;
}

.menu:hover {
  background-color: #3a6ea5;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.menu:hover p {

  display: block;
  transition: 0.3s;
}

.organizer__mainBody {
  /* width: 90%;
  margin: auto; */
  margin-top: 60px;

  margin-left: 100px;
  margin-right: 10px;
  overflow-y: scroll;
}
.organizer__mainBody::-webkit-scrollbar {
  display: none;
}
.organizer__header--links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
}
.link {
  width: 200px;
  padding: 0px 5px;
}
.link p {
  font-size: 25px;
}
.organizer__header--links p {
  font-family: "Rubik", sans-serif;
  text-align: center;
}

.organizer__header--links p:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.p-bg {
  background-color: rgba(135, 206, 250, 0.4);
  color: #ffffff;
  border-radius: 4px;
  padding: 6px;
}
@media (max-width: 788px) {
  .organizer__sidebar {
    display: none;
  }
  .organizer__header h2 {
    font-size: 25px;
  }
  .organizer__mainBody {
    margin-left: 0px;
  }
}

@media (max-width: 400px) {
  .organizer__header h2 {
    font-size: 18px;
  }
  .organizer__header--user {
    margin-right: 5px;
  }
  .left__heading {
    margin-left: 0px;
  }
  .left__heading p {
    font-size: 16px;
  }
  .eventName {
    left: 80px;
    width: 90px;
    text-wrap: wrap;
    word-break: break-all;
  }
}
