.root {
  width: 100%;
  margin: auto;
  margin-top: 10px;
}
.toolbar {
  background-color: #ececea;
  border-radius: 5px;
}
.blockType a {
  color: #000;
  text-decoration: none;
}
