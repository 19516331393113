@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap");
html {
  scroll-behavior: smooth;
  /* overflow-y: auto;*/
  overflow-x: hidden;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  /* width: 100vh;
  height: 100vh; */
  /* font-family: "Ubuntu"; */
  font-family: "Rubik", sans-serif;
  /* overflow-y: scroll;
  padding-left: "17px !important";
  padding-right: "17px !important"; */
}

a {
  color: #296dfa;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

#html5qr-code-full-region__dashboard_section_swaplink {
  visibility: hidden;
}

#html5qr-code-full-region > div:first-child {
  display: none;
}
