



.fil0 {
    fill: #0099FF
}

.fil1 {
    fill: #2EACFF
}

.fil3 {
    fill: #F2F2F2
}

.fil2 {
    fill: #E5E5E5;
    fill-rule: nonzero
}