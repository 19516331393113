.gm-style .gm-style-iw {
  /* background-color: #252525 !important; */
  top: 0 !important;
  left: 0 !important;
  /* width: 60% !important; */
  /* min-height: 200px; */
  /* !important;
  min-height: 70px; */
  /* !important; */
  /* max-height: 200px; */
  overflow: hidden;
  padding-top: 10px;
  display: block !important;
  border: 4px solid aqua;
}
.gm-style .gm-style-iw {
  /* height: 200px;
  min-height: 200px;
  max-height: 200px; */
  /* height: 120px;
  max-height: 240px; */
  overflow-y: auto;
}
.gm-style .gm-style-iw-t::after {
  background: linear-gradient(
    45deg,
    rgb(0 255 255) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
}
/*style the paragraph tag*/
.gm-style .gm-style-iw #google-popup p {
  padding: 10px;
}

/*style the annoying little arrow at the bottom*/
.gm-style div div div div div div div div {
  /* background-color: #252525 !important; */
  margin: 0;
  padding: 0;
  top: 0;
  font-size: 16px;
}

/*style the link*/
.gm-style div div div div div div div div a {
  font-weight: bold;
}
